import React from 'react';
import profileImg from '../../profile.jpg';
import { GoMarkGithub } from 'react-icons/go';
import { FaLinkedin } from 'react-icons/fa';
import { MdEmail } from 'react-icons/md';
import {
  DiJsBadge,
  DiHtml5,
  DiMongodb,
  DiCss3,
  DiReact,
  DiNodejsSmall,
  DiFirebase

} from 'react-icons/di';
import { SiExpress, SiTailwindcss, SiNextdotjs, SiTypescript, } from 'react-icons/si';
import { useMediaQuery } from '@mui/material';
function Main() {
  const lg = useMediaQuery('(min-width: 1000px');
  return (
    <div className='Main'>
      <div className='big-left'>
        <div className='middle'>
          <h1>
            <span>
              HELLO👋<br />
            </span>

            I'M PHILIPP, A FULL-STACK ENGINEER BASED IN LEIPZIG, GERMANY.
          </h1>
          <div className='left-side'>
            <img alt='profile' src={profileImg} />
            {lg && <div className='social-links left-side-links'>
              <a href='https://github.com/ignazka'>
                {' '}
                <GoMarkGithub />
              </a>
              <a href='https://www.linkedin.com/in/philippkastl'>
                {' '}
                <FaLinkedin />
              </a>
              <a href='mailto:philippkastl@gmail.com'>
                {' '}
                <MdEmail />
              </a>
            </div>}

          </div>


          {/* <p>
            blablabla blablabla bla of bringing an idea to fruition and find each
            step of the way exciting. blabla.
          </p> */}
        </div>
        {/* <hr /> */}

      </div>
      <div>
        <div className='right-side'>
          <div>
            <h2>FRONT-END</h2>
          </div>
          <div className='skills'>
            <div className='double-skill'>
              <div className='double-skill-half'>
                <div className='skill-ctn'>
                  <DiJsBadge />
                  <p>JavaScript</p>
                </div>
                <div className='skill-ctn'>
                  <SiTypescript />
                  <p>Typescript</p>
                </div>


                <div className='skill-ctn'>
                  <DiReact />
                  <p>React</p>
                </div>
                <div className='skill-ctn'>
                  <SiNextdotjs />
                  <p>Next</p>
                </div>
              </div>
              <div className='double-skill-half'>
                <div className='skill-ctn'>
                  <DiHtml5 />
                  <p>HTML5</p>
                </div>

                <div className='skill-ctn'>
                  <DiCss3 />
                  <p>CSS3</p>
                </div>
                <div className='skill-ctn'>
                  <SiTailwindcss />
                  <p>Tailwind</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='right-side'>
          <div>
            <h2>BACK-END</h2>
          </div>

          <div className='skills'>
            <div className='skill-ctn'>
              <DiNodejsSmall />
              <p>NodeJS</p>
            </div>
            <div className='skill-ctn'>
              <SiExpress />
              <p>Express</p>
            </div>

            <div className='skill-ctn'>
              <DiMongodb />
              <p>MongoDB</p>
            </div>
            <div className='skill-ctn'>
              <DiFirebase />
              <p>Firebase</p>
            </div>
          </div>
        </div>{' '}
      </div>

    </div>
  );
}

export default Main;
