import React from 'react';
import { IoIosMenu, IoIosClose } from 'react-icons/io';
import Drawer from '@mui/material/Drawer';

import { MdWork, MdEmail } from 'react-icons/md';
import { GoMarkGithub } from 'react-icons/go';
import { FaLinkedin } from 'react-icons/fa';
function MobileMenu() {
  const [click, setClick] = React.useState(false);
  const handleClick = () => setClick(!click);

  return (
    <div className='MobileMenu'>
      <Drawer open={click} onClick={handleClick}>
        <div className='menu-ctn'>
          <ul>
            <li>
              <a href='#projects'>
                <MdWork />
                &nbsp;PROJECTS
              </a>
            </li>
            <li>
              <a href='emailto:philippkastl@gmail.com'>
                <MdEmail />
                &nbsp;CONTACT{' '}
              </a>
            </li>
          </ul>
          <div className='menu-ctn-socialmedia-icons'>
            <GoMarkGithub />
            <FaLinkedin />
          </div>
        </div>
      </Drawer>
      <div className='mobile-menu' onClick={handleClick}>
        {click ? (
          <IoIosClose className='menu-icon' />
        ) : (
          <IoIosMenu className='menu-icon' />
        )}
      </div>
    </div>
  );
}

export default MobileMenu;
