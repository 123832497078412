import React from 'react';
import metronom1 from '../../pictures/metronom3.PNG';
import collective1 from '../../pictures/collective4.PNG';
import nutrients1 from '../../pictures/nutrients2.PNG';
import spotlight1 from '../../pictures/spotlight1.PNG';
import fakeflix from '../../pictures/fakeflix.png';
import moodz from '../../pictures/moodz.PNG'
import fakedin from '../../pictures/fakedin.PNG'
import { ProjectCard } from '../ProjectCard';

function Projects() {
  return (
    <div className='Projects' id='projects'>
      <h1>Projects (selection)</h1>
      <p>test account to all projects:<br /><br /> email: john@doe.de <br />password: 123456

      </p>
      <div className='Projects-grid'>
        <ProjectCard
          link='https://netflix-clone-ignazka.vercel.app/'
          title='Netflix Clone'
          img={fakeflix}
          next firebase node tailwind
        >
          Netflix clone with Typescript, NextJS and TailwindCSS.
          Subscription via Stripe.
        </ProjectCard>

        <ProjectCard
          link='https://fakedin.vercel.app/'
          title='LinkedIn Clone'
          img={fakedin}
          next mongo node tailwind
        >
          LinkedIn clone with Javascript, NextJS and TailwindCSS.
          Subscription via NextAuth.
        </ProjectCard>
        <ProjectCard
          link='https://moodzapp.netlify.app'
          title='moodZ'
          img={moodz}
          react firebase node tailwind
        >
          Mood diary. Track your mood level.

        </ProjectCard>
        <ProjectCard
          link='https://metroknowme.netlify.app/'
          title='MetronoMe'
          img={metronom1}
          mongo express react node
          api='Web Audio API'
        >
          Save the speed of different songs in a list. Play the speed as a click
          sound.
        </ProjectCard>
        <ProjectCard
          link='https://collective-choice.herokuapp.com/'
          title='Collective Choice'
          img={collective1}
          mongo express react node
        >
          Survey with comment function and User Management. Pair-Programming
        </ProjectCard>
        <ProjectCard
          link='https://ignazka.github.io/spotlight/'
          title='Spotlight'
          img={spotlight1}
          javascript handlebars
        >
          Mini-Game. Try to stay in a moving Circle!<br /> Game written in JS
          using Canvas and Local Storage.
        </ProjectCard>
        <ProjectCard
          link='https://ignazka.github.io/nutrients/'
          title='Nutrients'
          img={nutrients1}
          mongo express react node
          api='Edamame API'
        >
          Search for food and see the Nutrition Score.
        </ProjectCard>
      </div>
    </div >
  );
}

export default Projects;
