import React from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import { MobileMenu } from '../MobileMenu';
function Header() {
  const lg = useMediaQuery('(min-width:1000px)');

  return (
    <div className='Header'>
      {/* <div className='logo'>Philipp Kastl</div> */}
      {lg ? (
        <div className='links'>
          {/* <a href='#projects'>PROJECTS</a>
          {/* <a href='#'>ABOUT</a> */}
          {/* <a href='mailto:philippkastl@gmail.com'>CONTACT</a> */}
        </div>
      ) : (
        // <></>
        <MobileMenu />
      )}
    </div>
  );
}

export default Header;
