import './main.sass';
import { Header } from './components/Header';
import { Footer } from './components/Footer';
import { Main } from './components/Main';
import { Projects } from './components/Projects';
import { ScrollToTop } from './components/ScrollToTop';
function App() {
  return (
    <div className='outter-ctn'>
      <div className='App'>
        <div className='inner-ctn'>
          <Header />
          <ScrollToTop />
          <Main />
          <Projects />
          <Footer />
        </div>
      </div>
    </div>
  );
}

export default App;
