import React from 'react';
import { GoMarkGithub } from 'react-icons/go';
import { FaLinkedin } from 'react-icons/fa';
function Footer() {
  return (
    <div className='Footer'>
      <a href='https://github.com/ignazka'>
        <GoMarkGithub />
      </a>
      <a href='https://www.linkedin.com/in/philippkastl'>
        <FaLinkedin />
      </a>
      {/* <p>Blog</p> */}
    </div>
  );
}

export default Footer;
