import React from 'react';
import { FaAngleUp } from 'react-icons/fa';
import { useMediaQuery } from '@mui/material';
function ScrollToTop() {
  let lg = useMediaQuery('(min-width:600px)');

  const [showTopBtn, setShowTopBtn] = React.useState(false);

  React.useEffect(() => {
    let scroll;
    window.addEventListener('scroll', () => {
      lg ? (scroll = window.scrollY > 2000) : (scroll = window.scrollY > 1000);
      if (scroll) {
        setShowTopBtn(true);
      } else {
        setShowTopBtn(false);
      }
    });

  }, [lg]);
  const goToTop = () => {
    window.scrollTo({
      top: 0,
    });
  };
  return (
    <div className='ScrollToTop'>
      <div className='top-to-btm'>
        {' '}
        {showTopBtn && (
          <FaAngleUp className='icon-position icon-style' onClick={goToTop} />
        )}{' '}
      </div>
    </div>
  );
}

export default ScrollToTop;
