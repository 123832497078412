import React from 'react';
import mongodbLogo from '../../pictures/mongodb.svg';
import expressLogo from '../../pictures/expressjs_logo_icon_169185.png';
import reactLogo from '../../pictures/React-icon.svg.png';
import nodeLogo from '../../pictures/nodejs-icon.svg';
import handlebarsLogo from '../../pictures/cdnlogo.com_handlebars.svg';
import nextLogo from '../../pictures/Nextjs-logo.svg'
import firebaseLogo from '../../pictures/firebase-1.svg'
import javascriptLogo from '../../pictures/Unofficial_JavaScript_logo_2.svg.png';
import { useMediaQuery } from '@mui/material';
import tailwindLogo from '../../pictures/Tailwind_CSS_Logo.svg.png'
function ProjectCard({
  img,
  tailwind,
  typescript,
  firebase,
  next,
  children,
  javascript,
  handlebars,
  mongo,
  express,
  react,
  node,
  title,
  link,
}) {

  const [hover, setHover] = React.useState(false);
  const handleMouseOver = ({ target }) => {
    setHover(target.name);
    if (!lg) {
      const timer = setTimeout(() => {
        setHover(false);
      }, 1000);
      return () => clearTimeout(timer);
    }
  };

  const handleMouseOut = () => {
    setHover(false);
  };
  const lg = useMediaQuery('(min-width: 1000px)');
  return (
    <div className='project-card'>
      <div className='title'>
        <h3>{title}</h3>
      </div>
      <div className='card-text'>
        <div className='card-text-inner'>
          <div className='card-text-first'>
            <p>{children}</p>
          </div>
          <hr />
          <div className='card-text-second'>
            {javascript && (
              <div className='tooltip-parent'>
                <img
                  name='JavaScript'
                  alt='javascript'
                  onMouseOut={handleMouseOut}
                  onMouseOver={handleMouseOver}
                  src={javascriptLogo}
                />
                {hover === 'JavaScript' && (
                  <div className='tooltip'>JavaScript</div>
                )}
              </div>
            )}
            {handlebars && (
              <div className='tooltip-parent'>
                <img
                  name='Handlebars'
                  alt='Handlebars'
                  onMouseOut={handleMouseOut}
                  onMouseOver={handleMouseOver}
                  src={handlebarsLogo}
                  style={{ width: '60px' }}
                />
                {hover === 'Handlebars' && (
                  <div className='tooltip'>Handlebars</div>
                )}
              </div>
            )}
            {mongo && (
              <div className='tooltip-parent'>
                <img
                  alt='MongoDB'
                  name='MongoDB'
                  onMouseOut={handleMouseOut}
                  onMouseOver={handleMouseOver}
                  src={mongodbLogo}
                />
                {hover === 'MongoDB' && <div className='tooltip'>MongoDB</div>}
              </div>
            )}
            {express && (
              <div className='tooltip-parent'>
                <img
                  alt='express'
                  name='Express'
                  onMouseOut={handleMouseOut}
                  onMouseOver={handleMouseOver}
                  src={expressLogo}
                />
                {hover === 'Express' && <div className='tooltip'>Express</div>}
              </div>
            )}
            {react && (
              <div className='tooltip-parent'>
                <img
                  alt='react'
                  name='React'
                  onMouseOut={handleMouseOut}
                  onMouseOver={handleMouseOver}
                  src={reactLogo}
                />
                {hover === 'React' && <div className='tooltip'>React</div>}
              </div>
            )}
            {node && (
              <div className='tooltip-parent'>
                <img
                  alt='node'
                  name='Node'
                  onMouseOut={handleMouseOut}
                  onMouseOver={handleMouseOver}
                  src={nodeLogo}
                />
                {hover === 'Node' && <div className='tooltip'>Node</div>}
              </div>
            )}
            {next && (
              <div className='tooltip-parent'>
                <img
                  alt='next'
                  name='Next'
                  onMouseOut={handleMouseOut}
                  onMouseOver={handleMouseOver}
                  src={nextLogo}
                />
                {hover === 'Next' && <div className='tooltip'>Next</div>}
              </div>
            )}
            {firebase && (
              <div className='tooltip-parent'>
                <img
                  alt='firebase'
                  name='Firebase'
                  onMouseOut={handleMouseOut}
                  onMouseOver={handleMouseOver}
                  src={firebaseLogo}
                />
                {hover === 'Firebase' && <div className='tooltip'>Firebase</div>}
              </div>
            )}
            {typescript && (
              <div className='tooltip-parent'>
                <img
                  alt='typescript'
                  name='TypeScript'
                  onMouseOut={handleMouseOut}
                  onMouseOver={handleMouseOver}
                  src={firebaseLogo}
                />
                {hover === 'TypeScript' && <div className='tooltip'>Typescript</div>}
              </div>
            )}
            {tailwind && (
              <div className='tooltip-parent'>
                <img
                  alt='tailwind'
                  name='Tailwind'
                  onMouseOut={handleMouseOut}
                  onMouseOver={handleMouseOver}
                  src={tailwindLogo}
                />
                {hover === 'Tailwind' && <div className='tooltip'>Tailwind</div>}
              </div>
            )}
          </div>
        </div>
        <div className='image'>

          <a href={link} target='_blank'
            rel='noreferrer noopener'>
            <img src={img} alt={title} loading='lazy' />
          </a>
        </div>
      </div>
    </div>
  );
}

export default ProjectCard;
